import {
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Handle, useStore, Position } from "reactflow";
import { getTemplateData, submitSelectedTemplate } from "services/flowServices";
import defaultImageForPdf from "../../../../assets/images/pdf_placeholder_image.png";
import defaultImageForVideo from "../../../../assets/images/defaultVideoPic.png";
import subscriptionAction from "redux/subscription/action";
import { getImageUrl } from "services/flowServices";
import Enhancer from "../Enhancers/TemplateNodeEnhancer";

const connectionNodeIdSelector = state => state.connectionNodeId;
const { success, error, fetching } = subscriptionAction;

const handleStyle = { left: 10 };

function TestingTemplateNode({
  props,
  setSidebarState,
  setSelectedNode,
  onImageUpload,
  onTextChange,
  setIdForTemplate,
  setIsChildLoading,
  duplicateNode,
  setErrorsArray,
  isChildLoading,
  editId,
  deleteParentNode,
  setValues,
  errors,
  touched,
  values,
  submitCount,
  handleBlur
}) {
  const [parentId, setParentId] = useState();
  const [selectedTemplateID, setSelectedTemplateID] = useState("");
  const [dropdownOptions, setDropDownOptions] = useState([]);
  const [responseForTemplate, setResponseForTemplate] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [isPdf, setIsPdf] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [fileName, setFileName] = useState("");
  const [initialReload, setInitialReload] = useState(true);
  const [message, setMessage] = useState("");
  const [existingTemplateLoaded, setExistingTemplateLoaded] = useState(false);

  const [dropDownOptionsForVar, setDropDownOptionsForVar] = useState([]);

  const [valuesObj, setValuesObj] = useState({
    id: "",
    isVideo: "",
    isPdf: "",
    isImage: "",
    imageLink: "",
    fileName: "",
    variables: [],
    carousel: []
  });

  const handleDeleteParentNode = () => {
    deleteParentNode(props?.id);
  };

  // useEffect(() => {
  //   if (responseForTemplate == true && imageUrl == "") {
  //     setErrorsArray((arr) => {
  //       const index = arr.findIndex((x) => x?.id == props?.id);
  //       if (index === -1) {
  //         return [...arr, { id: props?.id }];
  //       }
  //       return arr; // If the error for this component already exists in the array, no need to update
  //     });
  //   } else {
  //     setErrorsArray((arr) => {
  //       const filteredArr = arr.filter((x) => x?.id !== props?.id);
  //       return [...filteredArr]; // Return a new array without the error for the current component
  //     });
  //   }
  // }, [imageUrl]);

  // useEffect(() => {
  //   const hasEmptyFileUrl = valuesObj?.carousel?.some(
  //     (carouselItem) => !carouselItem.fileUrl
  //   );

  //   console.log(valuesObj?.carousel, "has asd", responseForTemplate);

  //   // console.log()
  //   console.log(hasEmptyFileUrl, "has empty firle url");
  //   if (responseForTemplate) {
  //     console.log("inside useEffect  has adasdasdasds");

  //     setErrorsArray((arr) => {
  //       const index = arr.findIndex((x) => x?.id === props?.id);
  //       if (index === -1) {
  //         return [...arr, { id: props?.id }];
  //       }
  //       return arr;
  //     });
  //   } else {
  //     setErrorsArray((arr) => {
  //       const filteredArr = arr.filter((x) => x?.id !== props?.id);
  //       return filteredArr;
  //     });
  //   }
  // }, [props.valuesObj?.carousel, props?.id, responseForTemplate]);

  useEffect(() => {
    if (props?.data) {
      setSelectedTemplateID(props?.data?.value?.id ?? "");
      if (props?.data?.value?.id === undefined) {
        setExistingTemplateLoaded(true);
      }
      setIsPdf(props?.data?.value?.isPdf);
      if (props?.data?.value?.isPdf) {
        setPreview(defaultImageForPdf);
        setSelectedFile(props?.data?.value?.imageLink);
      } else {
        setPreview(props?.data?.value?.imageLink);
      }
      if (props?.data?.value && Object.keys(props.data.value).length !== 0) {
        // console.log(props?.data?.value, "props?.data?.value");
        setValuesObj(props?.data?.value);
        if (props?.data?.value?.imageLink !== "") {
          setPreview(props?.data?.value?.imageLink);
          setFileName(props?.data?.value?.fileName);
          setImageUrl(props?.data?.value?.imageLink);
          // setResponseForTemplate(props?.data?.value?.isPdf == true ? "Document" : props?.data?.value?.isVideo == true ? "Video" : props?.data?.value?.isImage == true  ? "Image" : null)
        }
      }
    }
    if (companyId) {
      // console.log(companyId, "companyId inside use effect responseForTemplate");
      handleGetDropdownData();
    }
  }, [companyId]);

  useEffect(() => {
    // console.log(responseForTemplate, "responseForTemplate");
    // changeHeight(props?.id, responseForTemplate, "template");

    if (responseForTemplate == false) {
      // console.log("responseForTemplate is fasle");
      setIsPdf(false);
      // setPreview(null);
      setSelectedFile(null);
    }
  }, [responseForTemplate]);

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;

  useEffect(() => {
    if (props?.id) {
      setParentId(props?.id);
    }
    const searchParams = new URLSearchParams(window.location.search);
    setCompanyId(prev => {
      // console.log("inside callback company id setting");
      return searchParams.get("company_id") || "";
    });
    // console.log(searchParams.get("company_id"), "company id setting");

    // console.log("company id setting", props);
  }, []);

  const prepareDropdownOptionsForVar = optionsArray => {
    try {
      let result = [];

      // Iterate through the keys of the input object
      Object.keys(optionsArray).forEach(key => {
        // Convert the key to a number (index)
        let value = parseInt(key);
        // Get the title from the object using the key
        let name = optionsArray[key];
        let label = optionsArray[key];
        // Create an object with index and title
        let item = { name, value, label };
        // Push the created object to the result array
        result.push(item);
      });

      setDropDownOptionsForVar(result);
    } catch (error) {
      console.log(error, "Error while trying to prepareDropdownOptionsForVar");
    }
  };

  // console.log(dropDownOptionsForVar, "values obj here in  drop down");

  const submitSelectedOption = async option => {
    try {
      const res = await submitSelectedTemplate(option);
      if (res.status === "success") {
        // console.log(res, "res from template api responseForTemplate");

        if (res?.data === false) {
          setResponseForTemplate("Without media");
        } else {
          setResponseForTemplate(res?.data);
        }

        if (initialReload === true) {
          setInitialReload(false);
        } else if (initialReload === false) {
          setPreview(null);
          setFileName("");
        }
        // setPreview(null);

        prepareDropdownOptionsForVar(res?.body?.contact_fields);
        // prepareVarToStore(
        //   res?.body?.variables?.body ? res?.body?.variables?.body : []
        // );
        // prepareCarouselToStore(
        //   res?.body?.variables?.carousel ? res?.body?.variables?.carousel : []
        // );

        if (existingTemplateLoaded) {
          prepareDataToStore(res?.body);
        } else {
          setExistingTemplateLoaded(true);
        }

        setMessage(res?.message);
        setSelectedFile(null);
      } else {
        // console.log(res, "Something went wrong");
      }
    } catch (error) {
      console.log(error, "error while trying to submit selected template");
    }
  };

  const prepareDataToStore = body => {
    try {
      let variablesResult = [];
      let carouselResult = [];

      // Check if variables array or object exists
      if (body?.variables) {
        if (Array.isArray(body.variables)) {
          // Case when variables is an array
          body.variables.forEach(item => {
            let id = item.id;
            let name = item.exampleValue;
            let dropdownValue = -2; // Initialize value as needed
            let textValue = name;
            let fileType = "image"; // Assume default as image
            let newObj = { id, name, dropdownValue, textValue, fileType };
            variablesResult.push(newObj);
          });
        } else if (typeof body.variables === "object") {
          // Case when variables is an object with 'body' property
          if (Array.isArray(body.variables.body)) {
            body.variables.body.forEach(item => {
              let id = item.id;
              let name = item.exampleValue;
              let dropdownValue = -2; // Initialize value as needed
              let textValue = name;
              let fileType = "image"; // Assume default as image
              let newObj = { id, name, dropdownValue, textValue, fileType };
              variablesResult.push(newObj);
            });
          }
        }
      }

      // Process carousel array
      if (Array.isArray(body?.variables?.carousel)) {
        body.variables.carousel.forEach(carouselItem => {
          let carouselVariables = []; // Array to store variables specific to each carousel item

          if (Array.isArray(carouselItem?.body)) {
            carouselItem.body.forEach(subItem => {
              let id = subItem.id;
              // let name = subItem.exampleValue;
              let dropdownValue = -2; // Initialize value as needed
              let textValue = subItem.exampleValue;
              let newObj = { id, dropdownValue, textValue };
              carouselVariables.push(newObj);
            });
          }

          // Create carousel item object with variables and other properties
          let carouselObj = {
            fileName: "", // Initialize as needed
            fileType: carouselItem.video ? "video" : "image", // Determine file type
            fileUrl: "", // Initialize as needed
            name: carouselItem.message, // Example usage for name based on your requirement
            id: carouselItem.index, // Example usage for id based on your requirement
            message: carouselItem.message, // Example usage for message based on your requirement
            variables: carouselVariables
          };

          carouselResult.push(carouselObj);
        });
      }

      let temp = {
        ...valuesObj,
        variables: variablesResult,
        carousel: carouselResult
      };

      setValuesObj(temp);
      onTextChange(props?.id, temp);
    } catch (error) {
      console.log(error, "Error while trying to prepareDataToStore");
    }
  };

  // const prepareVarToStore = (options) => {
  //   try {
  //     let result = [];

  //     // Iterate through each object in the input array
  //     options.forEach((item) => {
  //       // Extract id and exampleValue from the current object
  //       let id = item.id;
  //       let name = item.exampleValue;
  //       let dropdownValue = -2; // Initialize value as an empty string
  //       let textValue = name;
  //       // Create a new object with id, name, and value
  //       let newObj = { id, name, dropdownValue, textValue };

  //       // Push the new object to the result array
  //       result.push(newObj);
  //     });

  //     let temp = {
  //       ...valuesObj,
  //       variables: result,
  //     };
  //     setValuesObj(temp);
  //     onTextChange(props?.id, temp);
  //   } catch (error) {
  //     console.log(error, "Error while trying to prepareVarToStore");
  //   }
  // };

  // const prepareCarouselToStore = (options) => {
  //   try {
  //     let result = [];
  //     // Iterate through each object in the input array
  //     options.forEach((item) => {
  //       // Extract id and exampleValue from the current object
  //       let id = item.id;
  //       let name = item.exampleValue;
  //       let fileUrl = "";
  //       let fileName = "";
  //       // Create a new object with id, name, and value
  //       let newObj = { id, name, fileUrl, fileName };
  //       // Push the new object to the result array
  //       result.push(newObj);
  //     });
  //     let temp = {
  //       ...valuesObj,
  //       carousel: result,
  //     };
  //     setValuesObj(temp);
  //     onTextChange(props?.id, temp);
  //   } catch (error) {
  //     console.log(error, "Error while trying to prepareCarouselToStore");
  //   }
  // };

  useEffect(() => {
    if (selectedTemplateID) {
      submitSelectedOption(selectedTemplateID);
    }
  }, [selectedTemplateID]);

  const handleGetDropdownData = async () => {
    try {
      // console.log(companyId, "companyId inside function");
      const res = await getTemplateData(companyId);
      if (res.status === "success") {
        // console.log(res, "res from dropdown api");
        setDropDownOptions(res.data);
      } else {
        // console.log(res, "Something went wrong");
      }
    } catch (error) {
      console.log(error, "error while trying to get data for dropdown");
    }
  };

  const handleValueChange = e => {
    // console.log(e.target.value, "e in dropdown");

    if (e.target.value) {
      setSelectedTemplateID(e.target.value);
      // submitSelectedOption(e.target.value);

      const temp = {
        ...valuesObj,
        id: e.target.value
      };
      onTextChange(props?.id, temp);
      setValuesObj(temp);
      setIdForTemplate(props?.id, e.target.value);
    }
  };

  const handleSetMediaData = values => {
    const temp = {
      ...valuesObj,
      ...values
    };
    onTextChange(props?.id, temp);

    setValuesObj(temp);
  };
  const handleFileUpload = event => {
    try {
      setIsChildLoading(true);
      setIsLoading(true);
      const file = event.target.files[0];

      if (!file) {
        // No file selected
        return;
      }

      // Validate file size
      const maxSize = 15 * 1024 * 1024; // 15MB
      if (file.size > maxSize) {
        error("File size exceeds 15MB limit. Please choose a smaller file.");
        setIsLoading(false);

        return;
      }

      if (responseForTemplate === "DOCUMENT") {
        if (file.type !== "application/pdf") {
          error("Invalid file type. Please select a PDF file.");
          setIsLoading(false);

          return;
        }
      } else if (responseForTemplate === "IMAGE") {
        const allowedImageTypes = [
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/svg+xml"
        ];
        if (!allowedImageTypes.includes(file.type)) {
          error(
            "Invalid file type. Please select a .png, .jpg, .jpeg, or .svg file."
          );
          setIsLoading(false);

          return;
        }
      } else if (responseForTemplate === "VIDEO") {
        const allowedVideoTypes = [
          "video/mp4",
          "video/mpeg",
          "video/webm",
          "video/quicktime",
          "video/x-msvideo"
        ];

        if (!allowedVideoTypes.includes(file.type)) {
          error(
            "Invalid file type. Please select a .mp4, .mpeg, .webm, .quicktime, or .x-msvideo file."
          );
          setIsLoading(false);
          return;
        }
      }

      if (file.type.includes("image")) {
        // Handle image file
        handleImage(file, file.name);
      } else if (file.type === "application/pdf") {
        // Handle PDF file
        handlePDF(file, file.name);
      } else if (file.type.includes("video")) {
        handleVideo(file, file.name);
      } else {
        error(
          "Invalid file type. Please select an image (png, jpg, jpeg, svg) or a PDF file."
        );
      }
    } catch (error) {
      console.log(error, "error while trying to upload file");
    }
  };

  const handleImage = (file, filename) => {
    // Validate file type
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml"
    ];
    if (!allowedTypes.includes(file.type)) {
      error(
        "Invalid file type. Please select a .png, .jpg, .jpeg, .svg or .pdf file."
      );
      return;
    }

    // Read the file and convert to base64
    const reader = new FileReader();
    reader.onloadend = async () => {
      // const base64String = reader.result;
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
      setIsPdf(false);
      setFileName(filename);
      // You can now send the base64String to your backend
      const formData = new FormData();

      formData.append("file", file);
      formData.append("company_id", companyId);

      const imageUrlRes = await getImageUrl(formData);

      // console.log(imageUrlRes, "image url from s3");
      if (imageUrlRes?.status == "success") {
        setImageUrl(imageUrlRes?.url);
        const dataToSend = {
          imageLink: imageUrlRes?.url,
          fileName: filename,
          isImage: true,
          isPdf: false,
          isVideo: false
        };

        handleSetMediaData(dataToSend);
        // onImageUpload(props?.id, dataToSend, "image");
        setIsLoading(false);
        setIsChildLoading(false);

        // onImageUpload(props.id, base64String, "image");
      } else {
        setIsLoading(false);
        setPreview(null);
        setFileName(null);
      }
    };

    reader.readAsDataURL(file);
  };

  const handlePDF = (file, filename) => {
    // Read the file and convert to base64
    const reader = new FileReader();
    reader.onloadend = async () => {
      // const base64String = reader.result;
      setSelectedFile(file);
      // Set a default preview image for PDF files or display a PDF icon
      setPreview(defaultImageForPdf);
      setFileName(filename);

      // You can now send the base64String to your backend
      setIsPdf(true);

      const formData = new FormData();

      formData.append("file", file);
      formData.append("company_id", companyId);

      const imageUrlRes = await getImageUrl(formData);

      // console.log(imageUrlRes, "image url from s3");
      if (imageUrlRes?.status == "success") {
        setImageUrl(imageUrlRes?.url);
        const dataToSend = {
          imageLink: imageUrlRes?.url,
          fileName: filename,
          isImage: false,
          isPdf: true,
          isVideo: false
        };
        handleSetMediaData(dataToSend);
        // onImageUpload(props?.id, dataToSend, "pdf");
        setIsLoading(false);
        setIsChildLoading(false);
        // onImageUpload(props.id, base64String, "image");
      } else {
        setIsLoading(false);
        setPreview(null);
        setFileName(null);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleVideo = (file, filename) => {
    const allowedVideoTypes = [
      "video/mp4",
      "video/mpeg",
      "video/webm",
      "video/quicktime",
      "video/x-msvideo"
    ];

    if (!allowedVideoTypes.includes(file.type)) {
      error(
        "Invalid file type. Please select a .png, .jpg, .jpeg, .svg or .pdf file."
      );
      return;
    }

    const reader = new FileReader();
    reader.onloadend = async () => {
      // const base64String = reader.result;
      setSelectedFile(file);
      // Set a default preview image for PDF files or display a PDF icon
      setPreview(defaultImageForVideo);
      setFileName(filename);

      // You can now send the base64String to your backend
      setIsPdf(false);
      setIsVideo(true);

      const formData = new FormData();

      formData.append("file", file);
      formData.append("company_id", companyId);

      const imageUrlRes = await getImageUrl(formData);

      // console.log(imageUrlRes, "image url from s3");
      if (imageUrlRes?.status == "success") {
        setImageUrl(imageUrlRes?.url);
        const dataToSend = {
          imageLink: imageUrlRes?.url,
          fileName: filename,
          isImage: false,
          isPdf: false,
          isVideo: true
        };
        handleSetMediaData(dataToSend);
        setIsLoading(false);
        setIsChildLoading(false);
      } else {
        setIsLoading(false);
        setPreview(null);
        setFileName(null);
      }
    };
    reader.readAsDataURL(file);
  };
  const handleVariableOptionChange = (variableId, newValue, flag) => {
    // Find the variable by ID and update its value

    // console.log(variableId, "values obj here in ", newValue);

    let updatedVariables;
    if (flag === true) {
      updatedVariables = valuesObj.variables.map(variable =>
        variable.id === variableId
          ? { ...variable, dropdownValue: newValue }
          : variable
      );
    } else {
      updatedVariables = valuesObj.variables.map(variable =>
        variable.id === variableId
          ? { ...variable, textValue: newValue }
          : variable
      );
    }

    // Update the state with the new variables array
    let temp = {
      ...valuesObj,
      variables: updatedVariables
    };
    setValuesObj(temp);

    onTextChange(props?.id, temp);
  };

  const Error = () => {
    return (
      <>
        {/* <p className={`${props.class ? props.class : "error-msg w-100"}`}> */}
        {/* </p> */}
        {responseForTemplate == false ? (
          <aside className="error-message-row">
            <span className={"error-msg"}>Please select an option</span>
          </aside>
        ) : responseForTemplate !== false &&
          responseForTemplate !== "Without media" &&
          imageUrl == "" ? (
          <>
            <aside className="error-message-row">
              <span className={"error-msg"}>Media is required</span>
            </aside>
          </>
        ) : null}
      </>
    );
  };

  function replacePlaceholders(message, variables) {
    // Iterate over each variable in the array
    variables.forEach(variable => {
      const { id, dropdownValue, textValue, name } = variable;
      // Determine the value to replace based on dropdownValue

      let tempName;
      if (dropdownValue !== -2) {
        tempName = dropDownOptionsForVar.find(x => x.value === dropdownValue);
      }
      const valueToReplace =
        dropdownValue === -2 ? textValue : ` {$${tempName?.name}} `;
      // Construct the placeholder string using the id
      const placeholder = `{{${id}}}`;
      // Ensure valueToReplace is a string
      const valueToReplaceStr = valueToReplace.toString();
      // Replace all occurrences of the placeholder with the actual value
      while (message.includes(placeholder)) {
        message = message.replace(placeholder, valueToReplaceStr);
      }
    });
    return message;
  }

  function replaceCarouselPlaceholders(message, variables) {
    try {
      // Iterate over each variable in the array
      variables.forEach(variable => {
        const { id, dropdownValue, textValue, name } = variable;
        // Determine the value to replace based on dropdownValue

        let tempName;
        if (dropdownValue !== -2) {
          tempName = dropDownOptionsForVar.find(x => x.value === dropdownValue);
        }
        const valueToReplace =
          dropdownValue === -2 ? textValue : ` {$${tempName.name}} `;
        // Construct the placeholder string using the id
        const placeholder = `{{${id}}}`;
        // Ensure valueToReplace is a string
        const valueToReplaceStr = valueToReplace.toString();
        // Replace all occurrences of the placeholder with the actual value
        while (message.includes(placeholder)) {
          message = message.replace(placeholder, valueToReplaceStr);
        }
      });
      return message;
    } catch (error) {
      console.log(error, "Error while trying to replaceCarouselPlaceholders");
    }
  }

  const handleCarouselVariableOptionChange = (
    carouselId,
    variableId,
    newValue,
    isDropdownValue
  ) => {
    try {
      const updatedCarousel = valuesObj.carousel.map(carouselItem => {
        if (carouselItem.id === carouselId) {
          const updatedVariables = carouselItem.variables.map(variable => {
            if (variable.id === variableId) {
              return isDropdownValue
                ? { ...variable, dropdownValue: newValue }
                : { ...variable, textValue: newValue };
            }
            return variable;
          });

          return { ...carouselItem, variables: updatedVariables };
        }
        return carouselItem;
      });

      let temp = {
        ...valuesObj,
        carousel: updatedCarousel
      };

      setValuesObj(temp);
      onTextChange(props?.id, temp);
    } catch (error) {
      console.log(
        "Error while handling carousel variable option change",
        error
      );
    }
  };

  useEffect(() => {
    setValues(valuesObj);
    console.log("errors array in useEffect", errors);
  }, [valuesObj]);
  const handleCarouselFileUpload = async (carouselId, fileType, file) => {
    try {
      if (!file) {
        console.error("No File Selected");
        return;
      }

      // Validate file type based on fileType (image or video)
      if (fileType === "image") {
        const allowedTypes = [
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/svg+xml"
        ];
        if (!allowedTypes.includes(file.type)) {
          console.error(
            "Invalid file type. Please select a .png, .jpg, .jpeg, .svg file."
          );
          return;
        }
      } else if (fileType === "video") {
        const allowedVideoTypes = [
          "video/mp4",
          "video/mpeg",
          "video/webm",
          "video/quicktime",
          "video/x-msvideo"
        ];
        if (!allowedVideoTypes.includes(file.type)) {
          console.error("Invalid file type. Please select a video file.");
          error("Invalid file type. Please select a video file.");
          return;
        }
      }

      const fileName = file.name;

      // Upload file to S3 or your preferred storage service
      const formData = new FormData();
      formData.append("file", file);
      formData.append("company_id", companyId); // Adjust as per your application's needs

      // Example function getImageUrl which sends formData to backend and returns image URL
      const imageUrlRes = await getImageUrl(formData);

      if (imageUrlRes?.status === "success") {
        // Update the carousel item with fileName, fileUrl, and imageLink
        const updatedCarousel = valuesObj.carousel.map(carouselItem => {
          if (carouselItem.id === carouselId) {
            return {
              ...carouselItem,
              fileName: fileName,
              fileUrl: imageUrlRes.url // Assuming imageUrlRes contains the uploaded file URL
            };
          }
          return carouselItem;
        });

        // Update the state with the new carousel array
        let temp = {
          ...valuesObj,
          carousel: updatedCarousel
        };
        setValuesObj(temp);

        // Optionally, trigger any further actions (e.g., UI updates, API calls)
        onTextChange(props?.id, temp);

        console.log("File uploaded successfully:", fileName, imageUrlRes.url);
      } else {
        console.error("Failed to upload file. Server response:", imageUrlRes);
        // Handle error response as needed
      }
    } catch (error) {
      console.error("Error while handling carousel file upload:", error);
      // Handle other errors, logging or displaying them appropriately
    }
  };

  const Error2 = props => {
    const field1 = props.field;
    if (errors[field1] || touched[field1] || submitCount > 0) {
      // console.log("inside if any from formik");
      return (
        <>
          {/* <p className={`${props.class ? props.class : "error-msg w-100"}`}> */}
          {/* </p> */}
          <aside className="error-message-row">
            <span className={props.class ? props.class : "error-msg"}>
              {errors[field1]}
            </span>
          </aside>
        </>
      );
    } else {
      return <span />;
    }
  };

  // Modified Error component to handle errors for buttons
  const ButtonError = ({ index, flag, index2 }) => {
    if ((errors.carousel && errors.carousel[index]) || submitCount > 0) {
      console.log(" if any from formik Errors condition satisfied.");
      return (
        <aside style={{ marginTop: "5px" }} className="error-message-row">
          <span className={props.class ? props.class : "error-msg"}>
            {flag === true
              ? errors.carousel[index].fileUrl
              : Array.isArray(errors.carousel[index].fileUrl)
              ? errors.carousel[index].retailerId[index2]?.id
              : null}
          </span>
        </aside>
      );
    } else {
      // console.log("if any from formik No error.");
      return <span />;
    }
  };
  console.log(valuesObj, "values obj here");

  return (
    <>
      <>
        <div className="text-updater-node flow-comn-box" onClick={() => {}}>
          <div className="flow-title-box">
            <aside>Message</aside>
            {/* <Handle type="target" className="flow-trigger flow-trigger-fill" /> */}
            <>
              <div className="header-btn-row">
                <Tooltip title="Clone">
                  <div
                    onClick={() => {
                      duplicateNode(props?.id);
                    }}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-clone"></i>{" "}
                  </div>
                </Tooltip>
                <Tooltip title="Delete">
                  <div
                    onClick={handleDeleteParentNode}
                    className="preview-clone-button"
                  >
                    <i className="fas fa-trash"></i>{" "}
                  </div>
                </Tooltip>
              </div>
            </>
          </div>
          <div
            className="position-relative"
            onClick={() => {
              //   setSelectedNode(extractParentId(childNodeId));
              // setSidebarState(true)
            }}
            //  className="text-updater-node"
          >
            {/* <div className="flow-comn-close-btn" style={{ cursor: "pointer" }}>
        <i className="fas fa-times-circle"></i>
      </div> */}

            <div className="flow-inner-row">
              <div className="flow-inner-box pb-4">
                <InputLabel id={`demo-simple-select-label-${parentId}`}>
                  Template
                </InputLabel>

                <Select
                  className="nodrag dropdown-zindex"
                  style={{ width: "200px" }}
                  labelId={`demo-simple-select-label-${parentId}`}
                  id="demo-simple-select"
                  value={selectedTemplateID}
                  label="Age"
                  onChange={e => handleValueChange(e)}
                >
                  {dropdownOptions && dropdownOptions.length > 0 ? (
                    dropdownOptions.map((option, _index) => {
                      return (
                        <MenuItem value={option?.id} key={_index}>
                          {option?.title}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem disabled>Nothing To Select</MenuItem>
                  )}
                </Select>

                <Error2 field="id" />
              </div>

              {message && message.length && message.length > 0 ? (
                <>
                  <div className="flow-inner-box">
                    {replacePlaceholders(message, valuesObj.variables)}
                  </div>
                </>
              ) : null}

              {responseForTemplate !== false &&
              responseForTemplate !== "Without media" ? (
                <div className="flow-inner-box pb-4">
                  {fileName !== "" ? (
                    <>
                      <div
                        className="flow-file-input"
                        style={{ maxHeight: "200px" }}
                      >
                        <i className="fas fa-image"></i>
                        <label
                          htmlFor="fileuploadname"
                          className="file-upload-name-label"
                        >
                          {fileName}
                        </label>
                        <Input
                          type="file"
                          id="fileuploadname"
                          className="file-upload-name-input"
                          onChange={e => {
                            handleFileUpload(e);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flow-file-input">
                        <i className="fas fa-image"></i>
                        <input
                          type="file"
                          onChange={e => {
                            handleFileUpload(e);
                          }}
                        />
                      </div>
                      <div
                        style={{
                          backgroundColor: "#E2E2E2",
                          textTransform: "lowercase",
                          width: "100%",
                          margin: "10px 0 0",
                          padding: "5px 10px",
                          borderRadius: "3px"
                        }}
                        className="mb-4"
                      >
                        Please select {responseForTemplate}
                      </div>
                    </>
                  )}
                  {/* <div className="flow-file-input">
                        <i className="fas fa-image"></i>
                        <input
                          type="file"
                          onChange={(e) => {
                            handleFileUpload(e);
                          }}
                        />
                      </div> */}

                  {isLoading ? (
                    <>
                      {" "}
                      <div className="flow-image-preview-box py-4">
                        <div className="myuihub-loader3"></div>
                      </div>
                    </>
                  ) : preview ? (
                    <>
                      <div className="flow-image-preview-box">
                        <img
                          src={
                            isPdf === true
                              ? defaultImageForPdf
                              : isVideo === true
                              ? defaultImageForVideo
                              : preview
                          }
                          alt="Template header"
                        />
                      </div>
                    </>
                  ) : null}

                  <Error />
                </div>
              ) : null}

              {valuesObj?.variables?.length > 0 && (
                <div className="flow-inner-box">
                  {valuesObj?.variables?.map((variable, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        <span
                          style={{
                            margin: "5px",
                            marginBottom: "20px",
                            paddingBottom: "20px"
                          }}
                        >
                          {ind + 1}
                        </span>

                        <div
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <Select
                            className="nodrag dropdown-zindex"
                            style={{ width: "200px" }}
                            labelId={`demo-simple-select-var-label-${parentId}`}
                            id="demo-simple-select"
                            // value={
                            //   valuesObj.variables.find(
                            //     (v) => v.id === variable.index
                            //   )?.value || ""
                            // }
                            value={valuesObj.variables[ind].dropdownValue}
                            label={
                              valuesObj.variables.find(
                                v => v.id === variable.id
                              )?.label || ""
                            }
                          >
                            {dropDownOptionsForVar.length > 0 ? (
                              dropDownOptionsForVar.map((option, _index) => {
                                return (
                                  <MenuItem
                                    onClick={e =>
                                      handleVariableOptionChange(
                                        variable.id,
                                        option.value,
                                        true
                                      )
                                    }
                                    value={option?.value}
                                    key={_index}
                                  >
                                    {option?.name}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem disabled>Nothing To Select</MenuItem>
                            )}
                          </Select>
                        </div>
                        <div className="comn-or-text">
                          <span>or</span>
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                          <TextField
                            disabled={
                              valuesObj.variables[ind].dropdownValue === -2
                                ? false
                                : true
                            }
                            value={valuesObj.variables[ind].textValue}
                            onChange={e =>
                              handleVariableOptionChange(
                                variable.id,
                                e.target.value,
                                false
                              )
                            }
                            multiline
                            placeholder="Enter manually defined value"
                          />
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
              {valuesObj?.carousel?.length > 0 &&
                valuesObj?.carousel?.map((carouselItem, index) => (
                  <div className="flow-inner-box" key={index}>
                    <span
                      style={{
                        display: "block",
                        marginTop: "10px",
                        borderBottom: "1px solid #dddddd",
                        paddingBottom: "5px",
                        marginBottom: "5px"
                      }}
                    >
                      {replaceCarouselPlaceholders(
                        valuesObj?.carousel[index]["message"],
                        valuesObj?.carousel[index]["variables"]
                      )}
                    </span>
                    <div className="flow-file-input">
                      <i className="fas fa-image"></i>

                      <Input
                        type="file"
                        onChange={e => {
                          handleCarouselFileUpload(
                            index,
                            carouselItem?.fileType,
                            e.target.files[0]
                          );
                        }}
                      />
                      <div className="mt-2">
                        <ButtonError flag={true} index={index} />
                      </div>
                    </div>
                    {valuesObj?.carousel[index]["fileUrl"] !== "" ? (
                      <>
                        {valuesObj?.carousel[index]["fileType"] === "video" ? (
                          <img
                            src={defaultImageForVideo}
                            alt="Carousel media"
                          />
                        ) : (
                          <img
                            src={valuesObj?.carousel[index]["fileUrl"]}
                            alt="Carousel media"
                          />
                        )}
                      </>
                    ) : null}
                    {carouselItem?.variables?.map((variable, ind) => (
                      <div key={ind}>
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px"
                          }}
                        >
                          <Select
                            className="nodrag dropdown-zindex"
                            style={{ width: "200px" }}
                            labelId={`demo-simple-select-var-label-${parentId}`}
                            id="demo-simple-select"
                            value={variable.dropdownValue}
                            onChange={e =>
                              handleCarouselVariableOptionChange(
                                carouselItem.id,
                                variable.id,
                                e.target.value,
                                true
                              )
                            }
                          >
                            {dropDownOptionsForVar.length > 0 ? (
                              dropDownOptionsForVar.map(option => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>Nothing To Select</MenuItem>
                            )}
                          </Select>
                        </div>
                        <div className="comn-or-text">
                          <span>or</span>
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                          <TextField
                            disabled={variable.dropdownValue !== -2}
                            value={variable.textValue}
                            onChange={e =>
                              handleCarouselVariableOptionChange(
                                carouselItem.id,
                                variable.id,
                                e.target.value,
                                false
                              )
                            }
                            multiline
                            placeholder="Enter manually defined value"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
          {isConnecting ? (
            <>
              <Handle
                type="target"
                position={Position.Left}
                className="flow-trigger "
                style={{
                  overflow: "hidden",
                  width: "100%", // Adjust the width as needed
                  height: "100%",
                  // height: "110%",
                  background: isConnecting ? "transparent" : "transparent",
                  border: "none",
                  position: "absolute",
                  left: "0px", // Adjust position to the left side of the node
                  top: "50%",
                  zIndex: 9,
                  borderRadius: 0,
                  cursor: isConnecting ? "default" : "move" // Change cursor based on connection state
                }}
                isConnectable={isConnecting} // Disable connection when already connecting
              />
            </>
          ) : (
            <>
              <Handle
                type="target"
                position={Position.Left}
                className="flow-trigger flow-trigger-fill"
                style={{
                  left: "1px",
                  right: "auto"
                }}
                isConnectable={isConnecting} // Disable connection when already connecting
              />
            </>
          )}
        </div>
      </>
    </>
  );
}

export default Enhancer(TestingTemplateNode);
